import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/photography/chromatic-aberration",
  "date": "2012-12-20",
  "title": "CHROMATIC ABERRATION",
  "author": "admin",
  "tags": ["photography"],
  "featuredImage": "feature.jpg",
  "excerpt": "In optical lenses, chromatic aberration (also known as achromatism or chromatic distortion) is a type of  distortion in which there is a failure of a lens to focus all colors to the same convergence point."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`It occurs because lenses have a different refractive index for different wavelengths of light (the lens dispersion). The refractive index decreases with increasing wavelength.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/b0K2vPJ.jpg",
        "alt": "Chromatic Aberration"
      }}></img></p>
    <p>{`Edges of this photo reflects chromatic abrration`}</p>
    <p>{`This results in noticeable defects  in isolated highlights or along strong luminosity edges. There are two kinds,`}<strong>{` axial`}</strong>{` and `}<strong>{`lateral`}</strong>{`, and in lenses it is the lateral aberration that is common.`}</p>
    <p>{`Just like a glass prism dispersing a beam of light into a rainbow of colors, when different colors of light travel at different speeds in a medium (air , water etc.)  on to the sensor, the red, green and blue colors having different wavelength result in chromatic aberration. This has two major effects on the sensor too. Each channel (usually R, G, B) is blurry because it integrates a certain range of wavelengths that are imaged at different positions on the sensor.`}</p>
    <p>{`Photographic lenses comprise various dispersive, electric glasses. These glasses do not refract all constituent colors of incident light at equal angles, and great efforts may be required to design an overall well-corrected lens that brings all colors together in the same focus. Moreover, the different channels are shifted with respect to one another. This usually yields colored fringes along edges with a high level of contrast.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.dxomark.com/itext/measurements-and-protocols/aberration_chromatique.jpg",
        "alt": "Chromatic Aberration"
      }}></img></p>
    <p>{`Chromatic aberrations are those departures from perfect imaging that are due to dispersion. Whereas the Seidel aberrations are monochromatic, i.e. they occur also with light of a single color, chromatic aberrations are only noticed with polychromatic light.`}</p>
    <p>{`The role played in this by sensor blooming  is disputed, and is probably tied to a different aberration known as “purple fringing.” Lateral chromatic aberration shows itself as two opposed colors, usually red-cyan or blue-yellow, and is increasingly common because of wide-range zooms, which are difficult for lens manufacturers to correct across the range.`}</p>
    <p>{`(Image courtesy of DxO Labs)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      